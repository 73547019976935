import {
  Bar,
  BarChart,
  ReferenceLine,
  XAxis,
  ResponsiveContainer,
  Label,
  Customized,
} from 'recharts';

import * as React from 'react';
import { Box, Flex } from '@chakra-ui/react';

const CustomLabel = (props: any) => {
  const { value, viewBox } = props;
  return (
    <g transform={`translate(${viewBox.x},${viewBox.y})`}>
      <foreignObject x={'-60'} y="-45" width="120" height="200">
        <Box display="flex" alignItems="center" justifyContent="center" padding={2} margin="0 auto">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            px={3}
            py={1}
            fontSize="md"
            bg="accent.lightest"
            borderRadius="lg"
            boxShadow="dark"
            whiteSpace="nowrap"
            width="fit-content"
          >
            {value}
          </Box>
        </Box>
      </foreignObject>
    </g>
  );
};

export function SalaryBarChart({
  histograms,
  barsBGColor = '#96bece',
}: {
  histograms: { range: { min: number; max: number }; count: number; marker?: { label: string } }[];
  barsBGColor?: string;
}) {
  const markers = histograms
    .filter((histogram) => histogram.marker)
    .map((histogram) => {
      return {
        value: histogram.range.min,
        label: histogram.marker.label,
      };
    });

  return (
    <Flex position="relative" aspectRatio="16/9" justifyContent="center">
      <ResponsiveContainer>
        <BarChart data={histograms} margin={{ top: 40, right: 30, bottom: 80, left: 50 }}>
          <XAxis
            dataKey="range.min"
            tickFormatter={(value, index) => {
              const max = histograms[index].range.max;
              return `$${Math.round(value / 1000)}k - $${Math.round(max / 1000)}k`;
            }}
            interval={0}
            angle={-40}
            textAnchor="end"
            tickMargin={20}
            fontSize="16"
            color="#25383F"
            axisLine={false}
            tickLine={false}
          />
          <Bar dataKey="count" fill={barsBGColor} radius={[4, 4, 0, 0]} />

          {markers.map((marker) => (
            <ReferenceLine
              x={marker.value}
              stroke="#E8EFF2"
              strokeDasharray="3 3"
              key={marker.label}
            >
              <Label
                value={marker.label}
                position="top"
                fill="#333"
                fontSize="16"
                offset={10}
                content={<Customized component={<CustomLabel />} />}
              />
            </ReferenceLine>
          ))}
        </BarChart>
      </ResponsiveContainer>
    </Flex>
  );
}

export function SalaryInsigh({
  histograms,
}: {
  histograms: { range: { min: number; max: number }; count: number; marker?: { label: string } }[];
}) {
  return (
    <Box w={'600px'} m={'auto'} mt={'100px'}>
      <SalaryBarChart histograms={histograms} barsBGColor="#96bece" />
    </Box>
  );
}
