import { storyblokEditable, StoryblokComponent } from '@storyblok/react';
import { Box as ChakraBox, Flex, Container } from '@terminal/design-system';
import type { BoxStoryblok, Storyblok } from '../types';
import { selectBlokStyles } from '@helpers/storyblok.utils';

/**
 * Server as both Flex and Box
 * When its Box we will use display=flex with flex-direction=column
 */
export function Box({ blok, page }: Storyblok<BoxStoryblok>) {
  const {
    marginStyles,
    textStyles,
    layoutStyles,
    generalStyles,
    paddingStyles,
    sizesStyle,
    flexStyles,
  } = selectBlokStyles(blok);

  if (blok.hasPageSidePadding === true) {
    return (
      <ChakraBox
        {...storyblokEditable(blok)}
        {...generalStyles}
        {...marginStyles}
        {...layoutStyles}
      >
        <Container>
          {/* @ts-ignore the styling returns from storyblok is not typed, its only string therefore typescript throws an error */}
          <Flex
            //  We use direction instead of flexDirection in the stroyblok, therefor we have to use the same prop here to allow to get overwritten
            // @ts-ignore This type error is a bug in Chakra UI
            direction="column"
            {...sizesStyle}
            {...sizesStyle}
            {...textStyles}
            {...paddingStyles}
            {...flexStyles}
          >
            {blok.columns?.map((nestedBlok) => (
              <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} page={page} />
            ))}
          </Flex>
        </Container>
      </ChakraBox>
    );
  }

  return (
    // @ts-expect-error the styling returns from storyblok is not typed, its only string therefore typescript throws an error
    <ChakraBox
      {...storyblokEditable(blok)}
      {...generalStyles}
      {...textStyles}
      {...marginStyles}
      {...paddingStyles}
      {...sizesStyle}
      {...layoutStyles}
    >
      {blok.columns?.map((nestedBlok) => (
        <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} page={page} />
      ))}
    </ChakraBox>
  );
}
