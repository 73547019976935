/**
 * Return the site path with the current environment
 *
 * @description  Had to move this function out of sitePath.ts because tests would fail not being
 * able to read getConfig()
 */
export function sitePath_withEnv({
  path,
  lang,
  siteEnv,
}: {
  path: string;
  lang?: string;
  siteEnv: string;
}) {
  const langString = lang ? `/${lang}` : '';

  if (siteEnv === 'dev') {
    return `https://dev.terminal.io${langString + path}`;
  } else {
    return `https://www.terminal.io${langString + path}`;
  }
}

/**
 * Finds the key in an object that corresponds to a specific value
 * @param obj The object to search in
 * @param value The value to search for
 * @returns The key if found, undefined otherwise
 */
export function getKeyByValue<T extends Record<string, any>, V extends T[keyof T]>(
  obj: T,
  value: V,
): keyof T | undefined {
  return Object.keys(obj).find((key) => obj[key] === value) as keyof T | undefined;
}

export function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
