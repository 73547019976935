export default function titleize(slug: string, separator: string) {
  const words = slug.split(separator);
  return words
    .map((word) => word.charAt(0).toUpperCase() + word.substring(1).toLowerCase())
    .join(' ');
}

/**
 * Converts a hyphen-separated slug into a space-separated string with capitalized words.
 *
 * @example
 * slugifyToCapitalize('hello-world') // returns 'Hello World'
 * slugifyToCapitalize('my-cool-project') // returns 'My Cool Project'
 *
 * @param slug - A string containing hyphen-separated words
 * @returns A string with words separated by spaces and each word capitalized
 */
export function slugifyToCapitalize(slug: string) {
  return slug
    .split('-')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}
