import {
  Box,
  Flex,
  Text,
  Grid,
  GridItem,
  Tabs,
  TabList,
  TabPanels,
  Heading,
  TabPanel,
  Button,
  useTab,
  useMultiStyleConfig,
  Icon,
} from '@chakra-ui/react';
import { forwardRef, useRef, useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import ImageShimmer from '../ImageShimmer/ImageShimmer';

export default function VerticalTabs({
  type,
  text,
  tabs,
  defaultIndex,
  shouldHideImage = false,
  disableAutoRotation = false,
  children,
  ...props
}: {
  type: string;
  text?: string;
  defaultIndex?: number;
  shouldHideImage?: boolean;
  disableAutoRotation?: boolean;
  children?: React.ReactChild;
  tabs: {
    image?: {
      sourceUrl: string;
    };
    altText?: string;
    title: string;
    text: string;
  }[];
  [x: string]: any;
}) {
  const [ref, inView] = useInView({
    rootMargin: '-40% 0%',
    triggerOnce: true,
  });

  const [tabIndex, setTabIndex] = useState(0);

  const handleTabsChange = (index) => {
    setTabIndex(index);
  };

  useEffect(() => {
    if (disableAutoRotation) return;
    if (inView) {
      const timeout = setTimeout(() => {
        let next = tabIndex + 1;
        if (next === tabs.length) {
          next = 0;
        }
        setTabIndex(next);
      }, 4000);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [inView, tabIndex, tabs.length]);

  const CustomTab = forwardRef((props: any, ref) => {
    const tabProps = useTab({ ...props, ref });
    const isSelected = !!tabProps['aria-selected'];
    const styles = useMultiStyleConfig('Tabs', tabProps);
    const selectedContainer = useRef(null);

    return (
      <Button
        __css={styles.tab}
        {...tabProps}
        py="8"
        px="0"
        textAlign="left"
        position="relative"
        border="none"
        _before={{
          content: '""',
          position: 'absolute',
          left: '4rem',
          right: '0',
          margin: '0 auto',
          bottom: '0',
          width: 'calc(100% - 4rem)',
          height: 'px',
          bgColor: 'blue.200',
        }}
        _last={{
          _before: {
            display: 'none',
          },
        }}
        _active={{
          bgColor: 'blue.100',
        }}
        _focus={{
          boxShadow: isSelected ? 'none' : 'var(--chakra-shadows-outline)',
        }}
      >
        <Flex alignItems="center">
          <Box w="8" h="8" mr="8">
            {type === 'number' && (
              <Flex
                w="100%"
                h="100%"
                color="white"
                fontSize="xl"
                fontWeight="bold"
                lineHeight="5"
                bgColor={isSelected ? 'green.500' : 'grey.300'}
                alignItems="center"
                justifyContent="center"
              >
                {props.index}
              </Flex>
            )}
            {type === 'pin' && (
              <Icon
                viewBox="0 0 40 40"
                w="100%"
                h="100%"
                color={isSelected ? 'green.500' : 'grey.900'}
                position="relative"
                zIndex="2"
                transition="color 0.3s ease-in-out"
              >
                <path
                  d="M33.3337 16.0318C33.3337 23.0449 20.0003 36.6667 20.0003 36.6667C20.0003 36.6667 6.66699 23.0449 6.66699 16.0318C6.66699 9.01865 12.6365 3.33337 20.0003 3.33337C27.3641 3.33337 33.3337 9.01865 33.3337 16.0318Z"
                  fill="currentColor"
                  stroke="currentColor"
                  strokeWidth="3.33333"
                />
                <circle cx="20" cy="16.6666" r="5" fill="white" />
              </Icon>
            )}
          </Box>
          <Heading
            as="h3"
            fontSize="2rem"
            lineHeight="9"
            fontWeight={isSelected ? 'bold' : 'normal'}
          >
            {props.title}
          </Heading>
        </Flex>
        <Box
          pl="16"
          mt="2"
          fontSize="lg"
          fontWeight="normal"
          lineHeight="6"
          color="grey.900"
          maxHeight={isSelected ? selectedContainer.current.scrollHeight : '0'}
          transition="max-height .3s ease-in-out"
          overflow="hidden"
          ref={selectedContainer}
        >
          {props.text}
        </Box>
      </Button>
    );
  });

  return (
    <Box {...props}>
      <Tabs
        isLazy
        id="tabs"
        variant="unstyled"
        orientation="vertical"
        defaultIndex={defaultIndex ?? 0}
        index={tabIndex}
        onChange={handleTabsChange}
        ref={ref}
      >
        <Grid
          gridTemplateColumns={{ base: '1fr', lg: children ? '4.5fr 5.5fr' : '5.5fr 4.5fr' }}
          columnGap={{ lg: '14' }}
        >
          {text && (
            <GridItem gridArea="1 / 1 / 2 / 2">
              <Text mt="6" fontSize="lg" lineHeight="6">
                {text}
              </Text>
            </GridItem>
          )}
          {children}
          <GridItem gridArea={children ? '2 / 2 / 3 / 3' : '2 / 1 / 3 / 2'}>
            <TabList>
              {tabs.map((tab, index) => (
                <CustomTab key={index} index={index + 1} title={tab.title} text={tab.text} />
              ))}
            </TabList>
          </GridItem>
          {!shouldHideImage && (
            <GridItem gridArea="2 / 2 / 3 / 3">
              <TabPanels h="100%">
                {tabs.map((tab, index) => (
                  <TabPanel key={index} h="100%">
                    <Flex h="100%" alignItems="center">
                      <Box position="relative" w="100%" h="365px">
                        <ImageShimmer
                          src={tab.image.sourceUrl}
                          alt={tab.altText ? tab.altText : ''}
                          style={{ objectFit: 'contain' }}
                          fill
                        />
                      </Box>
                    </Flex>
                  </TabPanel>
                ))}
              </TabPanels>
            </GridItem>
          )}
        </Grid>
      </Tabs>
    </Box>
  );
}
