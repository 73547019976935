import type { ImageStoryblok, Storyblok } from '../types';
import Image from 'next/image';
import { forceExtractDimensionsFormURL, selectBlokStyles } from '@helpers/storyblok.utils';
import { Box, Link, Text } from '@terminal/design-system';
import { storyblokEditable } from '@storyblok/react';
import { generateStoryblokLinkUrl } from '../utils';

export function StoryblokImage({ blok }: Storyblok<ImageStoryblok>) {
  const imageLink = generateStoryblokLinkUrl(blok.link);

  const Wrapper = imageLink == null || !blok?.link || blok?.link?.url === '' ? Box : Link;

  const { width, height } = forceExtractDimensionsFormURL(blok.image?.filename, {
    width: 2000,
    height: 800,
  });

  const { marginStyles, sizesStyle, generalStyles } = selectBlokStyles(blok);

  const {
    width: _widthNotUserDefined,
    height: _heightNotUserDefined,
    ...restOfSizesStyles
  } = sizesStyle;

  const displaySizing = {
    width:
      Array.isArray(sizesStyle?.width) && sizesStyle?.width.length
        ? (sizesStyle?.width[0] as string)
        : (sizesStyle?.width as string),
    height:
      Array.isArray(sizesStyle?.height) && sizesStyle?.height.length
        ? (sizesStyle?.height[0] as string)
        : (sizesStyle?.height as string),
  };

  const styleProps = {
    stretch: {
      width,
      height,
      style: {
        objectFit: 'contain',
        display: 'block',
        width: '100%',
        position: 'relative',
        marginLeft: 0,
        marginRight: 0,
      },
    },
    default: {
      width,
      height,
      style: {
        ...displaySizing,
      },
    },
    left: {
      width,
      height,
      style: {
        ...displaySizing,
        float: 'left',
        marginLeft: 0,
      },
    },
    right: {
      width,
      height,
      style: {
        ...displaySizing,
        float: 'right',
        marginRight: 0,
      },
    },
    center: {
      width,
      height,
      style: {
        ...displaySizing,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
  } as const;

  const selectedStyle = styleProps[blok.laupoutMode || 'default'];

  const linkProps =
    imageLink == null || blok.link?.url === ''
      ? {}
      : ({ href: imageLink, target: blok.link?.target } as const);

  if (!blok.caption) {
    return (
      // @ts-ignore an issue with tyepscript compliler as adding {...{ href: imageLink, target: blok.link?.target }}  instead works
      <Wrapper {...linkProps} {...marginStyles} {...generalStyles} {...restOfSizesStyles}>
        <Image
          src={blok.image?.filename}
          alt={blok.alt || blok.image?.alt}
          title={blok?.title || blok?.image?.title}
          priority={blok.priority || false}
          {...selectedStyle}
          {...storyblokEditable(blok)}
        />
      </Wrapper>
    );
  }

  const stylePropsWithCaption = {
    stretch: {
      container: {
        ...marginStyles,
      },
      image: {
        width,
        height,
        style: {
          objectFit: 'contain',
          display: 'block',
          width: '100%',
          position: 'relative',
          marginLeft: 0,
          marginRight: 0,
        },
      },
    },
    default: {
      container: {
        ...marginStyles,
      },
      image: styleProps.default,
    },
    left: {
      container: {
        ...marginStyles,
        float: 'left',
        marginLeft: 0,
      },
      image: {
        width,
        height,
        style: {
          ...displaySizing,
        },
      },
    },
    right: {
      container: {
        ...marginStyles,
        float: 'right',
        marginRight: 0,
      },
      image: {
        width,
        height,
        style: {
          ...displaySizing,
        },
      },
    },
    center: {
      container: {
        ...marginStyles,
        display: 'flex',
        justifyContent: 'center',
      },
      image: {
        width,
        height,
        style: {
          ...displaySizing,
        },
      },
    },
  } as const;

  const selectedStyleWithCaption = stylePropsWithCaption[blok.laupoutMode || 'default'];

  return (
    // @ts-ignore
    <Wrapper
      {...linkProps}
      width="auto"
      {...selectedStyleWithCaption.container}
      {...storyblokEditable(blok)}
      {...generalStyles}
      {...marginStyles}
      {...restOfSizesStyles}
    >
      <Box>
        <Image
          src={blok.image?.filename}
          alt={blok.alt || blok.image.alt}
          title={blok?.title || blok?.image?.title}
          priority={blok.priority || false}
          {...selectedStyleWithCaption.image}
        />
        <Text variant="caption" color="text.secondary" mt={2}>
          {blok.caption}
        </Text>
      </Box>
    </Wrapper>
  );
}
