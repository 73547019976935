import Layout from '@components/Layout';
import { selectMetaRobots_forStoryblok } from '@helpers/metaRobots';
import sitePath from '@helpers/sitePath';
import { createCanonicalPath, returnPathWithStartingSlash } from '@helpers/storyblok.utils';
import { storyblokEditable, StoryblokComponent, ISbStory } from '@storyblok/react';
import getConfig from 'next/config';
import Head from 'next/head';
import sharecardImage from '@public/images/sharecard.jpg';
import type { SalaryInsightPageStoryblok, Storyblok } from '../types';
import { ExitPreviewMode } from '../ExitPreviewMode';
import {
  Flex,
  Heading,
  Box,
  Container,
  Text,
  Button,
  ArrowRightIcon,
} from '@terminal/design-system';
import { RichText } from '../primitive';
import { createTitles, SalaryInsight } from '@_pages/salary-insight';
import { SalaryInsightInfo, type SalaryInsightEligbility } from '@_pages/salary-insight/types';
import { levelAbbrToSlugMapping, roleNameToSlugMapping } from '@_pages/salary-insight/constants';
import Link from 'next/link';
import { useAugmentedRouter } from 'hooks/useAugmentedRouter';
import { trackCtaClicked } from '@lib/segment/trackAnalytics';

const { publicRuntimeConfig } = getConfig();
const { talentHubOnboardingUrl } = publicRuntimeConfig;

const footerCTA = {
  longText: false,
  text: 'Find the scale you need. Build your team today.',
  button: {
    url: talentHubOnboardingUrl,
    text: 'Build My Team',
  },
  image: '/images/home/businesses-footer.jpg',
};

function LampSVG() {
  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 64 65"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M36 57.1923C36 57.2044 35.9474 57.5326 35.1165 57.9161C34.3705 58.2604 33.2697 58.5 32 58.5C30.7303 58.5 29.6295 58.2604 28.8835 57.9161C28.0526 57.5326 28 57.2044 28 57.1923V55.5H36V57.1923Z"
        fill="#25383F"
        stroke="#25383F"
        stroke-width="2"
      />
      <circle cx="32" cy="23.5" r="18" fill="#F9C66C" />
      <rect x="23" y="28.5" width="17" height="17" fill="#F9C66C" />
      <path
        d="M50 23.5C50 27.6815 47.6395 32.8466 45.1963 36.2808C43.2862 38.9657 41.0448 42.2166 40.7692 45.5H38L38.9231 36.8333L50 23.5Z"
        fill="#F9C66C"
      />
      <path
        d="M14 23.5C14 27.6815 16.3605 32.8466 18.8037 36.2808C20.7138 38.9657 22.9552 42.2166 23.2308 45.5H26L25.0769 36.8333L14 23.5Z"
        fill="#F9C66C"
      />
      <path
        d="M40 54.7237C39.9856 54.7538 39.9249 54.8525 39.7071 55.0098C39.3763 55.2488 38.833 55.5056 38.0717 55.7382C36.5615 56.1996 34.4146 56.5 32 56.5C29.8368 56.5 27.8836 56.2589 26.4102 55.8743C25.672 55.6816 25.0825 55.4602 24.656 55.2327C24.387 55.0893 24.2201 54.9633 24.1206 54.869L24.0117 45.5L40 45.5V54.7237ZM40.004 54.7138C40.004 54.7138 40.0039 54.7145 40.0034 54.7158L40.004 54.7138Z"
        fill="white"
        stroke="#25383F"
        stroke-width="2"
      />
      <path
        d="M40 49.5C40 50.6046 36.4183 51.5 32 51.5C27.5817 51.5 24 50.6046 24 49.5"
        stroke="#25383F"
        stroke-width="2"
      />
      <path
        d="M34.7222 45.5C34.7222 40.0098 35.5778 28.1235 39 24.5C38.4815 25.1863 36.4333 26.5588 32.3889 26.5588H32"
        stroke="#25383F"
        stroke-width="2"
        stroke-linejoin="round"
      />
      <path
        d="M29.1765 45.5C29.1765 40.0098 28.1412 28.1235 24 24.5C24.6275 25.1863 27.1059 26.5588 32 26.5588"
        stroke="#25383F"
        stroke-width="2"
        stroke-linejoin="round"
      />
      <ellipse cx="32" cy="45" rx="9" ry="2.5" fill="#25383F" />
      <path d="M54 22.5H64" stroke="#25383F" stroke-width="2" />
      <path d="M10 22.5H0" stroke="#25383F" stroke-width="2" />
      <path d="M52 33.5L58.5 40" stroke="#25383F" stroke-width="2" />
      <path d="M11.5 33.5L5 40" stroke="#25383F" stroke-width="2" />
      <path d="M52 12L58.5 5.5" stroke="#25383F" stroke-width="2" />
      <path d="M12 12L5.5 5.5" stroke="#25383F" stroke-width="2" />
    </svg>
  );
}

function ManWithGlobeSVG() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 480 316"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1990_1529)">
        <g opacity="0.4">
          <rect x="31.5" y="105.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="31.5" y="137.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="31.5" y="169.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="31.5" y="201.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="31.5" y="233.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="31.5" y="265.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="31.5" y="297.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="59.5" y="105.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="59.5" y="137.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="59.5" y="169.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="59.5" y="201.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="59.5" y="233.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="59.5" y="265.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="59.5" y="297.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="87.5" y="105.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="87.5" y="137.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="87.5" y="169.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="87.5" y="201.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="87.5" y="233.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="87.5" y="265.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="87.5" y="297.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="115.5" y="105.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="115.5" y="137.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="115.5" y="169.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="115.5" y="201.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="115.5" y="233.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="115.5" y="265.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="115.5" y="297.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="143.5" y="105.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="143.5" y="137.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="143.5" y="169.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="143.5" y="201.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="143.5" y="233.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="143.5" y="265.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="143.5" y="297.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="171.5" y="105.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="171.5" y="137.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="171.5" y="169.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="171.5" y="201.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="171.5" y="233.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="171.5" y="265.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="171.5" y="297.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="199.5" y="105.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="199.5" y="137.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="199.5" y="169.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="199.5" y="201.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="199.5" y="233.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="199.5" y="265.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="199.5" y="297.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="227.5" y="105.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="227.5" y="137.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="227.5" y="169.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="227.5" y="201.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="227.5" y="233.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="227.5" y="265.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="227.5" y="297.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="255.5" y="105.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="255.5" y="137.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="255.5" y="169.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="255.5" y="201.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="255.5" y="233.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="255.5" y="265.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="255.5" y="297.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="283.5" y="105.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="283.5" y="137.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="283.5" y="169.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="283.5" y="201.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="283.5" y="233.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="283.5" y="265.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="283.5" y="297.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="311.5" y="105.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="311.5" y="137.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="311.5" y="169.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="311.5" y="201.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="311.5" y="233.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="311.5" y="265.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="311.5" y="297.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="339.5" y="105.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="339.5" y="137.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="339.5" y="169.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="339.5" y="201.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="339.5" y="233.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="339.5" y="265.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="339.5" y="297.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="367.5" y="105.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="367.5" y="137.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="367.5" y="169.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="367.5" y="201.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="367.5" y="233.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="367.5" y="265.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="367.5" y="297.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="395.5" y="105.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="395.5" y="137.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="395.5" y="169.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="395.5" y="201.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="395.5" y="233.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="395.5" y="265.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="395.5" y="297.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="423.5" y="105.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="423.5" y="137.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="423.5" y="169.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="423.5" y="201.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="423.5" y="233.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="423.5" y="265.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="423.5" y="297.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="451.5" y="105.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="451.5" y="137.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="451.5" y="169.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="451.5" y="201.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="451.5" y="233.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="451.5" y="265.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="451.5" y="297.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="479.5" y="105.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="479.5" y="137.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="479.5" y="169.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="479.5" y="201.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="479.5" y="233.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="479.5" y="265.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
          <rect x="479.5" y="297.5" width="13" height="13" stroke="#E8EFF2" stroke-width="3" />
        </g>
        <path
          d="M347.233 150.804C347.233 215.89 295.358 268.623 231.401 268.623C167.445 268.623 115.569 215.89 115.569 150.804C115.569 85.7189 167.445 32.9856 231.401 32.9856C295.358 32.9856 347.233 85.7189 347.233 150.804Z"
          fill="#25383F"
          stroke="#25383F"
          stroke-width="2"
        />
        <path
          d="M285.923 68.4914V47.5686L324.867 81.3313L312.597 94.9235C311.009 96.6831 308.247 96.6831 306.659 94.9235L288.242 74.5221C286.749 72.8683 285.923 70.7195 285.923 68.4914Z"
          fill="#25383F"
        />
        <path
          d="M324.597 104.671L340.007 115.557C340.584 128.364 341.045 159.359 338.276 180.876C335.507 202.393 312.893 230.827 301.932 242.354V214.56C301.932 211.38 302.824 208.263 304.507 205.564L312.802 192.264C315.317 188.232 313.784 182.91 309.509 180.834L294.885 173.732C291.785 172.226 289.817 169.082 289.817 165.636V140.145C289.817 137.921 290.641 135.775 292.13 134.122L317.997 105.408C319.692 103.526 322.528 103.209 324.597 104.671Z"
          fill="#25383F"
        />
        <path
          d="M253.419 62.169V39.4158C239.766 34.4819 203.152 30.9048 165.918 56.0677C128.684 81.2306 124.34 107.257 126.822 117.125L176.375 166.371C176.768 166.762 176.768 167.399 176.375 167.79L163.902 180.186C161.934 182.141 161.934 185.324 163.902 187.279L181.198 204.469C182.143 205.407 182.674 206.684 182.674 208.015V254.04H201.291L260.741 194.957C263.889 191.829 263.889 186.737 260.741 183.609L212.009 135.178C211.229 134.403 209.97 134.403 209.19 135.178L194.549 149.728C194.159 150.116 193.529 150.116 193.139 149.728L173.773 130.481C171.129 127.854 169.642 124.279 169.642 120.551V90.0563C169.642 89.149 170.753 88.7106 171.372 89.3736L192.091 111.548C192.981 112.5 194.529 112.361 195.234 111.266L223.336 67.6276C223.52 67.3417 223.837 67.169 224.177 67.169H248.419C251.18 67.169 253.419 64.9304 253.419 62.169Z"
          fill="#25383F"
        />
        <path
          d="M347.233 150.804C347.233 215.89 295.358 268.623 231.401 268.623C167.445 268.623 115.569 215.89 115.569 150.804C115.569 85.7189 167.445 32.9856 231.401 32.9856C295.358 32.9856 347.233 85.7189 347.233 150.804Z"
          stroke="#25383F"
          stroke-width="2"
        />
        <path
          d="M339.444 139.117C339.444 204.203 287.569 256.936 223.612 256.936C159.656 256.936 107.78 204.203 107.78 139.117C107.78 74.0319 159.656 21.2986 223.612 21.2986C287.569 21.2986 339.444 74.0319 339.444 139.117Z"
          fill="#8BBFD0"
          stroke="#25383F"
          stroke-width="2"
        />
        <path
          d="M278.134 56.8042V35.8813L317.078 69.6441L304.808 83.2362C303.22 84.9958 300.458 84.9958 298.87 83.2362L280.453 62.8349C278.96 61.181 278.134 59.0322 278.134 56.8042Z"
          fill="white"
        />
        <path
          d="M316.808 92.9835L332.218 103.869C332.795 116.677 333.256 147.672 330.487 169.189C327.718 190.706 305.104 219.139 294.143 230.666V202.873C294.143 199.693 295.035 196.576 296.718 193.877L305.014 180.576C307.528 176.544 305.995 171.222 301.72 169.147L287.097 162.045C283.996 160.539 282.028 157.395 282.028 153.949V128.458C282.028 126.233 282.852 124.087 284.341 122.434L310.208 93.7208C311.904 91.8386 314.739 91.5219 316.808 92.9835Z"
          fill="white"
        />
        <path
          d="M245.63 50.482V27.7288C231.977 22.7949 195.364 19.2178 158.129 44.3807C120.895 69.5436 116.551 95.5699 119.033 105.438L168.586 154.684C168.979 155.075 168.979 155.712 168.586 156.103L156.113 168.499C154.145 170.454 154.145 173.637 156.113 175.592L173.409 192.782C174.354 193.72 174.885 194.997 174.885 196.328V242.353H193.502L252.952 183.27C256.1 180.142 256.1 175.05 252.952 171.922L204.22 123.491C203.44 122.716 202.181 122.716 201.401 123.491L186.76 138.041C186.37 138.429 185.74 138.429 185.35 138.041L165.984 118.794C163.34 116.167 161.853 112.592 161.853 108.864V78.3693C161.853 77.462 162.964 77.0236 163.583 77.6866L184.302 99.8613C185.192 100.813 186.74 100.674 187.445 99.5787L215.547 55.9406C215.731 55.6547 216.048 55.482 216.388 55.482H240.63C243.391 55.482 245.63 53.2434 245.63 50.482Z"
          fill="white"
        />
        <path
          d="M339.444 139.117C339.444 204.203 287.569 256.936 223.612 256.936C159.656 256.936 107.78 204.203 107.78 139.117C107.78 74.0319 159.656 21.2986 223.612 21.2986C287.569 21.2986 339.444 74.0319 339.444 139.117Z"
          stroke="#25383F"
          stroke-width="2"
        />
        <ellipse
          cx="180.774"
          cy="215.083"
          rx="11.6832"
          ry="11.6871"
          fill="#25383F"
          stroke="#25383F"
          stroke-width="2"
        />
        <ellipse
          cx="176.88"
          cy="211.188"
          rx="11.6832"
          ry="11.6871"
          fill="white"
          stroke="#25383F"
          stroke-width="2"
        />
        <ellipse cx="153.513" cy="129.378" rx="11.6832" ry="11.6871" fill="white" />
        <ellipse
          cx="153.513"
          cy="129.378"
          rx="11.6832"
          ry="11.6871"
          fill="#25383F"
          stroke="#25383F"
          stroke-width="2"
        />
        <ellipse
          cx="149.619"
          cy="125.482"
          rx="11.6832"
          ry="11.6871"
          fill="white"
          stroke="#25383F"
          stroke-width="2"
        />
        <ellipse
          cx="200.246"
          cy="55.3599"
          rx="11.6832"
          ry="11.6871"
          fill="#25383F"
          stroke="#25383F"
          stroke-width="2"
        />
        <ellipse
          cx="196.351"
          cy="51.4642"
          rx="11.6832"
          ry="11.6871"
          fill="white"
          stroke="#25383F"
          stroke-width="2"
        />
        <ellipse
          cx="192.457"
          cy="160.544"
          rx="11.6832"
          ry="11.6871"
          fill="#25383F"
          stroke="#25383F"
          stroke-width="2"
        />
        <ellipse
          cx="188.563"
          cy="156.648"
          rx="11.6832"
          ry="11.6871"
          fill="white"
          stroke="#25383F"
          stroke-width="2"
        />
        <path
          d="M417.876 260.979L412.394 302.091L413.715 315.635H375.642L364.493 300.681L361.181 315.635H317.419L329.475 252.204L360.28 223.44L417.876 260.979Z"
          fill="#25383F"
        />
        <path
          d="M350.267 56.034V73.01C350.267 73.01 353.381 89.7425 361.955 98.5036H377.538C377.538 98.5036 392.738 87.1371 392.738 74.5157V56.034C392.738 43.612 383.234 33.5444 371.503 33.5444C359.771 33.5444 350.267 43.612 350.267 56.034Z"
          stroke="#25383F"
          stroke-width="3"
          stroke-miterlimit="10"
        />
        <path
          d="M348.674 43.2944C344.291 45.7522 343.509 50.9115 343.509 50.9115C361.38 53.2512 388.126 47.3318 388.126 47.3318C388.126 47.3318 384.208 54.5355 393.845 68.0942L398.493 57.5912C398.493 51.3322 399.231 45.5751 391.343 44.4458C391.343 44.4458 394.228 35.8619 381.176 29.3888L380.541 34.7252C380.541 34.7252 378.047 27.7945 365.312 28.5917L368.64 34.3561C368.64 34.3561 364.073 28.9238 353.249 31.6252L356.65 36.8509C356.65 36.8509 351.264 33.0793 344.343 40.4454L348.674 43.2944Z"
          fill="#25383F"
          stroke="#25383F"
          stroke-width="3"
          stroke-linejoin="round"
        />
        <path
          d="M358.863 81.9632H381.198C381.198 81.9632 375.856 75.1802 369.215 75.1802C362.575 75.1802 358.863 81.9632 358.863 81.9632Z"
          fill="#25383F"
          stroke="#25383F"
          stroke-width="3"
          stroke-linejoin="round"
        />
        <path
          d="M366.788 68.0942C366.788 68.0942 366.485 74.5747 364.073 77.2908C364.073 77.2908 367.762 81.5053 372.455 77.2908C372.729 77.3164 373.005 77.2772 373.261 77.1765C373.517 77.0757 373.745 76.9162 373.928 76.7108C374.112 76.5054 374.244 76.2599 374.315 75.9939C374.385 75.728 374.393 75.4492 374.336 75.1799"
          fill="white"
        />
        <path
          d="M366.788 68.0942C366.788 68.0942 366.485 74.5747 364.073 77.2908C364.073 77.2908 367.762 81.5053 372.455 77.2908C372.729 77.3164 373.005 77.2772 373.261 77.1765C373.517 77.0757 373.745 76.9162 373.928 76.7108C374.112 76.5054 374.244 76.2599 374.315 75.9939C374.385 75.728 374.393 75.4492 374.336 75.1799"
          stroke="#25383F"
          stroke-width="3"
          stroke-miterlimit="10"
        />
        <path
          d="M358.863 252.233H329.475L320.746 235.655L327.07 217.875L333.71 120.846L357.321 100.659L361.985 98.5627L362.508 116.624L394.457 104.246L403.016 108.807C411.951 113.568 419.843 120.07 426.227 127.929C432.611 135.788 437.358 144.846 440.189 154.569C443.797 166.976 447.228 181.273 448.467 194.035C451.478 225.034 438.462 230.555 438.462 230.555L413.538 220.709L405.348 252.233H372.779"
          fill="#BFD897"
        />
        <path
          d="M333.696 120.853C333.696 120.853 313.42 153.875 298.958 138.501C296.944 136.368 294.892 134.072 292.834 131.718L291.638 126.093L285.072 123.584L265.578 134.81C274.152 150.31 286.975 170.165 300.161 173.663C319.551 178.793 330.368 169.626 330.368 169.626"
          fill="#BFD897"
        />
        <path
          d="M350.268 60.529C350.268 60.529 347.065 56.4621 345.936 59.2816C344.808 62.1011 347.021 71.6372 350.268 75.1801"
          stroke="#25383F"
          stroke-width="3"
          stroke-miterlimit="10"
        />
        <path
          d="M391.756 65.74C391.756 65.74 396.11 55.8422 399.873 59.5105C403.636 63.1788 398.707 76.9146 391.329 76.6268"
          fill="white"
        />
        <path
          d="M391.756 65.74C391.756 65.74 396.11 55.8422 399.873 59.5105C403.636 63.1788 398.707 76.9146 391.329 76.6268"
          stroke="#25383F"
          stroke-width="3"
          stroke-miterlimit="10"
        />
        <path
          d="M373.997 61.8063C373.997 61.8063 379.449 58.8539 383.212 62.9798L373.997 61.8063Z"
          fill="white"
        />
        <path
          d="M373.997 61.8063C373.997 61.8063 379.449 58.8539 383.212 62.9798"
          stroke="#25383F"
          stroke-width="3"
          stroke-miterlimit="10"
        />
        <path
          d="M363.468 61.8061C363.468 61.8061 358.022 58.9128 354.252 63.0092L363.468 61.8061Z"
          fill="white"
        />
        <path
          d="M363.468 61.8061C363.468 61.8061 358.022 58.9128 354.252 63.0092"
          stroke="#25383F"
          stroke-width="3"
          stroke-miterlimit="10"
        />
        <path
          d="M378.047 81.5276C378.047 81.5276 370.455 92.7465 363.401 85.2918"
          stroke="#25383F"
          stroke-width="3"
          stroke-miterlimit="10"
        />
        <path
          d="M359.941 68.3234C359.065 68.3234 358.354 67.613 358.354 66.7366C358.354 65.8601 359.065 65.1497 359.941 65.1497C360.817 65.1497 361.527 65.8601 361.527 66.7366C361.527 67.613 360.817 68.3234 359.941 68.3234Z"
          fill="#25383F"
        />
        <path
          d="M378.011 68.3234C377.134 68.3234 376.424 67.613 376.424 66.7366C376.424 65.8601 377.134 65.1497 378.011 65.1497C378.887 65.1497 379.597 65.8601 379.597 66.7366C379.597 67.613 378.887 68.3234 378.011 68.3234Z"
          fill="#25383F"
        />
        <path
          d="M370.735 99.323H362.826V106.748L370.735 99.323Z"
          fill="#25383F"
          stroke="#25383F"
          stroke-width="3"
          stroke-linejoin="round"
        />
        <path
          d="M405.318 252.203L395.844 265.969L409.251 282.509L417.876 262.138L433.393 228.562L413.53 220.709L405.318 252.203Z"
          fill="white"
          stroke="#25383F"
          stroke-width="3"
          stroke-linejoin="round"
        />
        <path
          d="M394.516 264.256C394.914 264.817 411.937 286.665 411.937 286.665"
          stroke="#25383F"
          stroke-width="3"
          stroke-miterlimit="10"
        />
        <path
          d="M392.738 115.479C392.738 115.479 387.455 125.754 373.251 128.167C373.251 128.167 364.847 116.306 359.97 120.986C355.093 125.665 356.65 142.257 356.65 142.257H350.267"
          stroke="#25383F"
          stroke-width="3"
          stroke-linejoin="round"
        />
        <path
          d="M358.259 123.547C358.259 123.547 355.883 116.816 348.763 120.299C341.642 123.783 353.19 104.062 362.833 99.323"
          stroke="#25383F"
          stroke-width="3"
          stroke-linejoin="round"
        />
        <path
          d="M320.739 235.626L343.295 213.062"
          stroke="#25383F"
          stroke-width="3"
          stroke-linejoin="round"
        />
        <path
          d="M342.631 164.954C342.631 164.954 365.799 169.53 383.212 185.657C383.212 185.657 365.504 182.292 352.961 185.657"
          stroke="#25383F"
          stroke-width="3"
          stroke-linejoin="round"
        />
        <path
          d="M411.937 166.858C411.937 166.858 423.787 191.953 419.168 209.247C419.168 209.247 412.328 211.859 413.538 220.709"
          stroke="#25383F"
          stroke-width="3"
          stroke-miterlimit="10"
        />
        <path
          d="M386.533 90.311L394.45 104.246"
          stroke="#25383F"
          stroke-width="3"
          stroke-miterlimit="10"
        />
        <path
          d="M253.846 105.412L241.694 91.7428L253.994 73.4751L260.922 80.0515C260.922 80.0515 260.922 74.9734 263.136 73.4751C265.349 71.9768 266.131 80.7084 266.323 82.8046C266.515 84.9007 271.075 96.5109 267.105 99.8766L283.788 124.233L267.828 133.423L253.846 105.412Z"
          fill="white"
          stroke="#25383F"
          stroke-width="3"
          stroke-linejoin="round"
        />
        <path
          d="M253.994 73.4756C253.994 73.4756 261.512 79.2179 261.778 85.3367C262.044 91.4554 247.693 93.3228 247.693 93.3228"
          fill="white"
        />
        <path
          d="M253.994 73.4756C253.994 73.4756 261.512 79.2179 261.778 85.3367C262.044 91.4554 247.693 93.3228 247.693 93.3228"
          stroke="#25383F"
          stroke-width="3"
          stroke-linejoin="round"
        />
        <path
          d="M364.493 300.681L351.094 291.529"
          stroke="white"
          stroke-width="3"
          stroke-miterlimit="10"
        />
        <path
          d="M417.876 314.896H312.136"
          stroke="#25383F"
          stroke-width="3"
          stroke-linejoin="round"
        />
        <path
          d="M291.631 126.056L282.652 137.452"
          stroke="#25383F"
          stroke-width="3"
          stroke-linejoin="round"
        />
        <path
          d="M294.988 33.3478C294.988 49.6118 281.808 62.7957 265.55 62.7957C249.293 62.7957 236.113 49.6118 236.113 33.3478C236.113 17.0839 249.293 3.8999 265.55 3.8999C281.808 3.8999 294.988 17.0839 294.988 33.3478Z"
          fill="#25383F"
          stroke="#25383F"
          stroke-width="2"
        />
        <path
          d="M294.988 30.4479C294.988 46.7119 281.808 59.8958 265.55 59.8958C249.293 59.8958 236.113 46.7119 236.113 30.4479C236.113 14.184 249.293 1 265.55 1C281.808 1 294.988 14.184 294.988 30.4479Z"
          fill="white"
          stroke="#25383F"
          stroke-width="2"
        />
        <ellipse cx="265.55" cy="30.4478" rx="20.2919" ry="20.2986" fill="#EFD58B" />
        <path
          d="M270.106 21.8689L265.395 21.2178C263.562 20.9646 261.808 22.0479 261.214 23.7997L261.147 23.9965C260.405 26.1841 261.739 28.5289 263.999 29.0085L267.937 29.8444C269.874 30.2555 271.185 32.0663 270.971 34.0347L270.944 34.2839C270.711 36.4257 268.769 37.9613 266.631 37.694L261.824 37.0929"
          stroke="#25383F"
          stroke-width="2"
          stroke-linecap="round"
        />
        <line
          x1="266.483"
          y1="21.7476"
          x2="266.483"
          y2="15.948"
          stroke="#25383F"
          stroke-width="2"
        />
        <line
          x1="266.483"
          y1="43.0129"
          x2="266.483"
          y2="37.2133"
          stroke="#25383F"
          stroke-width="2"
        />
      </g>
      <defs>
        <clipPath id="clip0_1990_1529">
          <rect width="480" height="315" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export type SalaryPageStoryblok<T> = {
  blok: T;
  page: Omit<Storyblok<T>['page'], 'extra'> & {
    extra: {
      country: string;
      role: (typeof roleNameToSlugMapping)[keyof typeof roleNameToSlugMapping] | null;
      level: (typeof levelAbbrToSlugMapping)[keyof typeof levelAbbrToSlugMapping] | null;
      // TODO: rename to SalryInsightElegibility
      salaryInsightEligbility: SalaryInsightEligbility;
      salaryInsightInfo: SalaryInsightInfo;
      footer: ISbStory;
    };
  };
};

export function SalaryInsightPage({ blok, page }: SalaryPageStoryblok<SalaryInsightPageStoryblok>) {
  const additionalSEO = blok.AdditionalSEO?.find((eachSEO) => eachSEO.component === 'seo');

  const { country, role, level } = page.extra;
  const {
    pageTitle,
    salaryMedianSubTitle,
    pageMiddleContentTitle,
    pageMetaTitle,
    pageMetaDescription,
  } = createTitles({
    country,
    role,
    level,
  });

  const { queryStringText, router } = useAugmentedRouter();

  const metaTitle = [pageMetaTitle, blok.SEO?.title || null, 'terminal.io']
    .filter(Boolean)
    .join(' - ');

  const { footer } = page.extra;

  const meta = {
    ...blok.SEO,
    title: metaTitle,
    description: pageMetaDescription,
    og_title: blok.SEO?.og_title || metaTitle,
    og_image: blok.SEO?.og_image || sharecardImage.src,
    og_description: blok.SEO?.og_description || pageMetaDescription,
  };

  // If the url had role and/or level, select and potentially append that to the page.full_slug
  const roleAndLevelPath = Array.isArray(router.query.slug)
    ? (router.query.slug || []).slice(1).join('/')
    : router.query;
  const pageFullCanonicalPath = roleAndLevelPath
    ? `${page.full_slug}/${roleAndLevelPath}`
    : page.full_slug;

  return (
    <>
      <Head>
        <link rel="canonical" href={createCanonicalPath(pageFullCanonicalPath)} />
        <title>{meta.title}</title>
        <meta property="og:title" content={meta.og_title} />
        <meta name="description" content={meta.description} />
        <meta property="og:description" content={meta.og_description} />
        <meta
          property="og:url"
          content={sitePath(returnPathWithStartingSlash(pageFullCanonicalPath))}
        />
        <meta property="og:image" content={meta.og_image} />
        <meta name="robots" content={selectMetaRobots_forStoryblok(additionalSEO?.robots)} />
      </Head>
      <ExitPreviewMode preview={page.preview} currentSlug={page.full_slug} />
      <Layout type="businesses">
        <main {...storyblokEditable(blok)}>
          <Container mt={10} mb={20}>
            <Flex
              direction={{ base: 'column', md: 'row' }}
              align={{ base: 'center', md: 'flex-end' }}
              justifyItems="baseline"
              justify="space-between"
              gap={[2, 2, 4, 6, 10]}
            >
              <Box maxW={{ base: 'full', md: '50%' }} mb={{ base: 8, md: 12 }}>
                <Heading as="h1" size="2xl" mb={4}>
                  {pageTitle}
                </Heading>
                <RichText
                  blok={{
                    _uid: blok.topDescription?._uid,
                    component: 'richText',
                    content: blok.topDescription,
                  }}
                  page={page}
                />
              </Box>
              <Box aspectRatio={480 / 316} w={['15rem', '15rem', '20rem', '25rem', 'full']}>
                <ManWithGlobeSVG />
              </Box>
            </Flex>
            <Box>
              <SalaryInsight
                country={country}
                role={role}
                level={level}
                salaryInsightEligbility={page.extra.salaryInsightEligbility}
                histograms={page.extra.salaryInsightInfo.histograms}
                medianSalary={page.extra.salaryInsightInfo.medianSalary}
                salaryMedianSubTitle={salaryMedianSubTitle}
                queryStringText={queryStringText}
              />
            </Box>
            {blok.underChartAreaContent && (
              <Box px={10} pt={3}>
                <RichText
                  blok={{
                    _uid: blok.underChartAreaContent?._uid,
                    component: 'richText',
                    content: blok.underChartAreaContent,
                  }}
                  page={page}
                />
              </Box>
            )}
          </Container>
          <Box bg="bg.secondary" py={15}>
            <Container px="10%">
              <Flex justifyContent="center">
                <Heading as="h2" variant="heading-2" mb={4}>
                  {pageMiddleContentTitle}
                </Heading>
              </Flex>
              {blok.middleContent?.map((nestedBlok) => (
                <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} page={page} />
              ))}
            </Container>
          </Box>
          <Box bg="bg.tertiary" py={15}>
            <Container>
              <Flex
                gap={10}
                alignItems={['flex-start', 'flex-start', 'flex-start', 'center']}
                flexDirection={['column', 'column', 'column', 'row']}
              >
                <LampSVG />

                <Flex direction="column" flex={3}>
                  <Heading as="h3" variant="heading-3" mb={4}>
                    Want in-depth expert insights about this data?
                  </Heading>
                  <Text>
                    Download our comprehensive Remote Engineer Salary Insights Report. Packed with
                    data and insights about global tech talent salaries, the report offers
                    invaluable information for individuals and teams.
                  </Text>
                </Flex>
                <Button
                  as={Link}
                  href={`/guide/remote-software-engineer-salary-insights-report${queryStringText}`}
                  minW="content-fit"
                  onClick={() => {
                    trackCtaClicked({
                      link: '/guide/remote-software-engineer-salary-insights-report',
                      name: 'Download the Report',
                      type: 'businesses',
                      context: 'Salary Insights',
                    });
                  }}
                >
                  Download the Report
                </Button>
              </Flex>
            </Container>
          </Box>
          <Container>
            <StoryblokComponent
              blok={{
                _uid: `salaryInsightList-${country}-${role}-${level}}`,
                component: 'salaryInsightList',
              }}
              page={page}
            />
          </Container>
        </main>
        <StoryblokComponent
          key={footer.data.story.uuid}
          blok={footer.data.story.content}
          page={{
            ...page,
            footerCTA: { ...footerCTA, footerType: 'businesses', withCTA: blok.showFooterCTA },
          }}
        />
      </Layout>
    </>
  );
}
