import { Box, CheckboxButton, Heading, Text } from '@terminal/design-system';
import { SalaryBarChart } from './SalaryBarChar';
import { FormControl } from '@chakra-ui/react';
import { Flex, FormLabel, Select, Button } from '@terminal/design-system';
import {
  levelAbbrToFilterTitleMapping,
  levelAbbrToSlugMapping,
  roleAbbreviationToNameMapping,
  roleAbbreviationToSlugMapping,
  roleNameToSlugMapping,
  SalaryInsightSlugs,
} from '@_pages/salary-insight/constants';
import { useRouter } from 'next/router';
import NextLink from 'next/link';

import { SalaryInsightEligbility } from './types';
import Link from 'next/link';
import slugify from '@helpers/slugify';
import { slugifyToCapitalize } from '@helpers/titleize';
import { trackCtaClicked } from '@lib/segment/trackAnalytics';
import { selectEligibleLevels } from './serializer';

function SalaryInsightPerRoleDropdownNavigation({
  country,
  role,
  salaryInsightEligbility,
  queryStringText,
}: {
  country: string;
  role: (typeof roleNameToSlugMapping)[keyof typeof roleNameToSlugMapping] | null;
  salaryInsightEligbility: SalaryInsightEligbility;
  queryStringText: string;
}) {
  const router = useRouter();

  return (
    <Flex gap="6" flexDirection={{ base: 'column', md: 'row' }} alignItems="flex-start">
      <FormControl>
        <FormLabel>Hiring Market</FormLabel>
        <Select
          id="country"
          name="country"
          value={slugify(country)}
          onChange={(e) => {
            const linktTo = `/${SalaryInsightSlugs.root}/${e.target.value}/${SalaryInsightSlugs.allRoles}`;

            router.push(`${linktTo}${queryStringText}`);
          }}
        >
          {Object.keys(salaryInsightEligbility).map((country) => (
            <option value={slugify(country)} key={`country-${country}`}>
              {country}
            </option>
          ))}
        </Select>
      </FormControl>

      <FormControl>
        <FormLabel>Role</FormLabel>
        <Select
          id="role"
          name="role"
          value={role || SalaryInsightSlugs.allRoles}
          onChange={(e) => {
            const role = e.target.value;

            const linkTo =
              role === SalaryInsightSlugs.allRoles
                ? `/${SalaryInsightSlugs.root}/${country}`
                : `/${SalaryInsightSlugs.root}/${country}/${role}`;

            router.push(`${linkTo}${queryStringText}`, undefined, { scroll: false });
          }}
        >
          <option value={SalaryInsightSlugs.allRoles}>All Roles</option>
          {country
            ? Object.keys(salaryInsightEligbility[slugifyToCapitalize(country)]).map((role) => (
                <option value={roleAbbreviationToSlugMapping[role]} key={`role-${role}`}>
                  {roleAbbreviationToNameMapping[role]}
                </option>
              ))
            : []}
        </Select>
      </FormControl>
    </Flex>
  );
}

export function SalaryInsight({
  country,
  role,
  level,
  salaryInsightEligbility,
  medianSalary,
  histograms,
  salaryMedianSubTitle,
  queryStringText,
}: {
  country: string;
  role: (typeof roleNameToSlugMapping)[keyof typeof roleNameToSlugMapping] | null;
  level: (typeof levelAbbrToSlugMapping)[keyof typeof levelAbbrToSlugMapping] | null;
  salaryInsightEligbility: SalaryInsightEligbility;
  histograms: { range: { min: number; max: number }; count: number; marker?: { label: string } }[];
  medianSalary: string;
  salaryMedianSubTitle: string;
  queryStringText: string;
}) {
  return (
    <>
      <Box borderTopRadius="md" border="1px" borderColor="bg.tertiary">
        <Box
          py={5}
          px={20}
          bg="bg.secondary"
          borderTopRadius="md"
          border="1px"
          borderColor="bg.tertiary"
        >
          <SalaryInsightPerRoleDropdownNavigation
            country={country}
            role={role}
            salaryInsightEligbility={salaryInsightEligbility}
            queryStringText={queryStringText}
          />
        </Box>
        <Box p={[2, 2, 4, 10]}>
          <Flex justifyContent="center" alignItems="center" direction="column">
            <Text variant="label">Engineer Seniority Level</Text>
            <Flex gap={4} mt={2} flexWrap="wrap" justifyContent="center">
              <Link
                href={`/${SalaryInsightSlugs.root}/${country}${
                  role ? `/${role}${queryStringText}` : queryStringText
                }`}
                key={`level-all`}
                scroll={false}
              >
                <CheckboxButton
                  checkedIcon={<></>}
                  unCheckedIcon={<></>}
                  size="sm"
                  isChecked={level === null}
                  borderRadius="md"
                  w="fit-content"
                  borderColor="ui.secondary"
                  minW="initial"
                  onClick={() => {}}
                >
                  <Text>All Levels</Text>
                </CheckboxButton>
              </Link>
              {selectEligibleLevels(country, role, salaryInsightEligbility).map(
                (universalLevel) => (
                  <Link
                    href={`/${SalaryInsightSlugs.root}/${country}/${
                      role || SalaryInsightSlugs.allRoles
                    }/${levelAbbrToSlugMapping[universalLevel]}${queryStringText}`}
                    key={`level-${universalLevel}`}
                    scroll={false}
                  >
                    <CheckboxButton
                      checkedIcon={<></>}
                      unCheckedIcon={<></>}
                      borderRadius="md"
                      w="fit-content"
                      minW="initial"
                      borderColor="ui.secondary"
                      size="sm"
                      isChecked={level === levelAbbrToSlugMapping[universalLevel]}
                      key={`level-${universalLevel}`}
                      onClick={() => {}}
                    >
                      <Text>{levelAbbrToFilterTitleMapping[universalLevel]}</Text>
                    </CheckboxButton>
                  </Link>
                ),
              )}
            </Flex>
          </Flex>
          <Flex
            h="fit-content"
            flexDir={['column-reverse', 'column-reverse', 'column-reverse', 'row']}
            justifyContent="space-between"
            alignItems="center"
            mt={8}
            position="relative"
          >
            <Box w={['full', 'full', 'full', '30%']} mt={['10', '10', '10', '0']}>
              <Box bg="ui.inverse.secondary" p={6} my="auto">
                <Heading as="p" color="brand.main" variant="heading-3" size="md">
                  USD {medianSalary}*
                </Heading>

                <Text fontWeight="bold" mt={3}>
                  {salaryMedianSubTitle}
                </Text>
              </Box>
            </Box>
            <Box w={['full', 'full', 'full', '70%']} flex={1}>
              <SalaryBarChart histograms={histograms} barsBGColor="#96bece" />
            </Box>
          </Flex>
          <Flex
            gap={2}
            justifyContent="center"
            pt={6}
            mt={8}
            flexDirection={['column', 'column', 'row']}
          >
            <Button
              as={NextLink}
              variant="outline"
              colorScheme="brand"
              w={['full', 'full', '21.11rem']}
              href={`/contact${queryStringText}`}
              onClick={() => {
                trackCtaClicked({
                  link: '/contact',
                  name: 'Talk to Us',
                  type: 'businesses',
                  context: 'Savings Insights',
                });
              }}
            >
              Talk to Us
            </Button>
            <Button
              as={NextLink}
              variant="solid"
              colorScheme="brand"
              w={['full', 'full', '21.11rem']}
              href={`https://hub.terminal.io/onboard${queryStringText}`}
              target="_blank"
              onClick={() => {
                trackCtaClicked({
                  link: 'https:/hub.terminal.io/onboard',
                  name: 'See Available Developers',
                  type: 'businesses',
                  context: 'Savings Insights',
                });
              }}
            >
              See Available Developers
            </Button>
          </Flex>
        </Box>
      </Box>
    </>
  );
}
