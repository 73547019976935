import gql from 'graphql-tag';

export const SelectSalaryData = gql`
  query SelectSalaryData($version: Int!, $country: String!, $and_where: [salary_data_bool_exp!])
  @cached(ttl: 604800) {
    salary_data(
      where: { version_id: { _eq: $version }, country: { _eq: $country }, _and: $and_where }
    ) {
      country
      role
      level
      salary_median
      hist_bin_1
      hist_bin_2
      hist_bin_3
      hist_bin_4
      hist_bin_5
      hist_bin_6
      hist_bin_7
      hist_bin_8
    }
    country_only_salary_data: salary_data(
      where: {
        version_id: { _eq: $version }
        country: { _eq: $country }
        role: { _is_null: true }
        level: { _is_null: true }
      }
    ) {
      salary_median
    }
    salary_data_version_by_pk(id: $version) {
      hist_bin_1
      hist_bin_2
      hist_bin_3
      hist_bin_4
      hist_bin_5
      hist_bin_6
      hist_bin_7
      hist_bin_8
    }
  }
`;
