import { SalaryInsightInfo, UniversalSeniorityLevel, type SalaryInsightEligbility } from './types';
import { SelectSalaryDataEligibilityQuery, SelectSalaryDataQuery } from './data';
import { SalaryInsight } from './SalaryInsight';
import { capitalizeFirstLetter, getKeyByValue } from '@helpers/general.utils';
import {
  levelAbbrToCopyTitleMapping,
  levelAbbrToFilterTitleMapping,
  levelAbbrToSlugMapping,
  roleAbbreviationToNameMapping,
  roleAbbreviationToSlugMapping,
  roleNameToSlugMapping,
  SalaryInsightSlugs,
} from './constants';
import { Role_Choices_Enum } from '@terminal/global/types/hasura-tables.generated.types';
import { slugifyToCapitalize } from '@helpers/titleize';

export function serializeSalaryInsightEligbility(
  data: SelectSalaryDataEligibilityQuery,
): SalaryInsightEligbility {
  const eligbility: SalaryInsightEligbility = {};

  data.salary_data_eligibility.forEach(({ country, role, level }) => {
    if (!eligbility[country]) {
      eligbility[country] = {} as Record<Role_Choices_Enum, UniversalSeniorityLevel[]>;
    }

    if (!eligbility[country][role]) {
      eligbility[country][role] = [];
    }

    if (!eligbility[country][role].includes(level as UniversalSeniorityLevel)) {
      eligbility[country][role].push(level as UniversalSeniorityLevel);
    }
  });

  return eligbility;
}

export function serializeSalaryData(data: SelectSalaryDataQuery): SalaryInsightInfo {
  const histogramBuckets = [
    'hist_bin_1',
    'hist_bin_2',
    'hist_bin_3',
    'hist_bin_4',
    'hist_bin_5',
    'hist_bin_6',
    'hist_bin_7',
    'hist_bin_8',
  ] as const;

  if (data.salary_data.length === 0) {
    return {
      histograms: [],
      medianSalary: '',
      countryOnly_medianSalary: '',
    };
  }

  const histograms: React.ComponentProps<typeof SalaryInsight>['histograms'] = histogramBuckets.map(
    (bucket) => {
      const min = data.salary_data_version_by_pk[bucket]['min'];
      const max = data.salary_data_version_by_pk[bucket]['max'];

      const isMedian =
        min <= data.salary_data[0].salary_median && max >= data.salary_data[0].salary_median;

      return {
        range: {
          min: min,
          max: max,
        },
        count: data.salary_data[0][bucket],
        marker: isMedian ? { label: 'Median' } : null,
      };
    },
  );

  return {
    histograms,
    medianSalary: Math.round(data.salary_data[0].salary_median).toLocaleString(),
    countryOnly_medianSalary: Math.round(
      data.country_only_salary_data[0].salary_median,
    ).toLocaleString(),
  };
}

export function createTitles({
  country,
  role,
  level,
}: {
  country: string;
  role: (typeof roleNameToSlugMapping)[keyof typeof roleNameToSlugMapping] | null;
  level: (typeof levelAbbrToSlugMapping)[keyof typeof levelAbbrToSlugMapping] | null;
}): {
  pageTitle: string;
  salaryMedianSubTitle: string;
  pageMiddleContentTitle: string;
  pageMetaTitle: string;
  pageMetaDescription: string;
} {
  const pageTitleRole = role
    ? `${getKeyByValue(roleNameToSlugMapping, role)}`
    : 'Software Engineer';

  const roleText = role ? `${getKeyByValue(roleNameToSlugMapping, role)}s` : 'Software Engineer';

  const levelText = level
    ? `${levelAbbrToCopyTitleMapping[getKeyByValue(levelAbbrToSlugMapping, level)]} `
    : '';

  const countryText = slugifyToCapitalize(country);

  const pageMetaDescription = role
    ? `Explore ${levelText}${
        roleAbbreviationToNameMapping[getKeyByValue(roleAbbreviationToSlugMapping, role)]
      } salary expectations in ${slugifyToCapitalize(
        country,
      )} in our Remote Software Engineer Salary Center. Learn what different roles & levels of experience should earn.`
    : `Explore ${levelText}software engineer salary expectations in ${slugifyToCapitalize(
        country,
      )} in our Remote Software Engineer Salary Center. Learn what different roles & levels of experience should earn.`;

  return {
    pageTitle: `Remote ${pageTitleRole} Salaries in ${countryText}`,
    salaryMedianSubTitle: `Median ${levelText}${roleText} Salary in ${countryText}`,
    pageMiddleContentTitle: `About ${roleText} Salaries in ${countryText}`,
    pageMetaTitle: `Remote ${levelText}${pageTitleRole} Salaries in ${countryText}`,
    pageMetaDescription,
  };
}

const defualtNoRoleTemplate = 'Software Engineers in {country}';
const defualtWithRoleTemplate = '{role}s in {country}';
/**
 * If any templates is provided, it will uses the template to create the copy after replacing
 * the values. Otherwise, uses the default copy in respect with the values.
 */
export function createCountryRoleLevelCopyByTemplate({
  country,
  role,
  level,
  withoutRoleTemplate,
  withRoleTemplate,
}: {
  country: string;
  role: (typeof roleNameToSlugMapping)[keyof typeof roleNameToSlugMapping] | null;
  level: (typeof levelAbbrToSlugMapping)[keyof typeof levelAbbrToSlugMapping] | null;
  withoutRoleTemplate: string | null;
  withRoleTemplate: string | null;
}): string {
  if (role && level) {
    if (withRoleTemplate) {
      return capitalizeFirstLetter(
        withRoleTemplate
          .replace(
            '{level}',
            `${levelAbbrToCopyTitleMapping[getKeyByValue(levelAbbrToSlugMapping, level)]}`,
          )
          .replace('{role}', `${getKeyByValue(roleNameToSlugMapping, role)}`)
          .replace('{country}', slugifyToCapitalize(country)),
      );
    }

    return defualtWithRoleTemplate
      .replace('{role}', `${getKeyByValue(roleNameToSlugMapping, role)}`)
      .replace('{country}', slugifyToCapitalize(country));
  }

  if (role && !level) {
    if (withRoleTemplate) {
      return capitalizeFirstLetter(
        withRoleTemplate
          .replace(' {level} ', ' ')
          .replace('{level} ', '')
          .replace(' {level}', '')
          .replace('{level}', '')
          .replace('{role}', `${getKeyByValue(roleNameToSlugMapping, role)}`)
          .replace('{country}', slugifyToCapitalize(country)),
      );
    }

    return defualtWithRoleTemplate
      .replace('{role}', `${getKeyByValue(roleNameToSlugMapping, role)}`)
      .replace('{country}', slugifyToCapitalize(country));
  }

  if (!role && level) {
    if (withoutRoleTemplate) {
      return capitalizeFirstLetter(
        withoutRoleTemplate
          .replace(' {role} ', ' ')
          .replace('{role} ', '')
          .replace(' {role}', '')
          .replace('{role}', '')
          .replace(
            '{level}',
            `${levelAbbrToCopyTitleMapping[getKeyByValue(levelAbbrToSlugMapping, level)]}`,
          )
          .replace('{country}', slugifyToCapitalize(country)),
      );
    }

    return defualtNoRoleTemplate.replace('{country}', slugifyToCapitalize(country));
  }

  // No role and no level
  if (withoutRoleTemplate) {
    return capitalizeFirstLetter(
      withoutRoleTemplate
        ? withoutRoleTemplate
            .replace(' {level} ', ' ')
            .replace('{level} ', '')
            .replace(' {level}', '')
            .replace('{level}', '')
            .replace(' {role} ', ' ')
            .replace('{role} ', '')
            .replace(' {role}', '')
            .replace('{role}', '')
            .replace('{country}', slugifyToCapitalize(country))
        : `Software Engineers in ${slugifyToCapitalize(country)}`,
    );
  }

  return defualtNoRoleTemplate.replace('{country}', slugifyToCapitalize(country));
}

export function selectEligibleLevels(
  country: string,
  role:
    | (typeof roleNameToSlugMapping)[keyof typeof roleNameToSlugMapping]
    | typeof SalaryInsightSlugs.allRoles
    | null,
  salaryInsightEligbility: SalaryInsightEligbility,
) {
  if (role === SalaryInsightSlugs.allRoles || role === null) {
    const uniqueLevels = new Set<UniversalSeniorityLevel>();

    Object.values(salaryInsightEligbility[slugifyToCapitalize(country)]).forEach((levels) => {
      levels.forEach((level) => {
        uniqueLevels.add(level);
      });
    });

    return Array.from(uniqueLevels);
  }

  return salaryInsightEligbility[slugifyToCapitalize(country)][
    getKeyByValue(roleAbbreviationToSlugMapping, role)
  ];
}
