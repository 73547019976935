import { storyblokEditable } from '@storyblok/react';
import { Text } from '@terminal/design-system';
import { SalaryInsightCountryMedianStoryblok } from '../types';
import { SalaryPageStoryblok } from '../contentPages/SalaryInsightPage';
import { selectBlokStyles } from '@helpers/storyblok.utils';

/**
 * Must only be used inside of SalaryPage content type.
 */
export function SalaryInsightCountryMedian({
  blok,
  page,
}: SalaryPageStoryblok<SalaryInsightCountryMedianStoryblok>) {
  if (!page.extra.salaryInsightInfo.countryOnly_medianSalary) {
    return null;
  }

  const { textStyles } = selectBlokStyles(blok);

  /**
   * The following css is added to the global css in order to display this text
   * inline between other p elements of the richtetxt
   *
   * ```
   * Target p elements that come immediately before .rich-text-inline
   * p:has(+ .rich-text-inline),
   *
   * Target p elements that come immediately after .rich-text-inline
   * .rich-text-inline + p {
   *   display: inline;
   * }
   * ```
   */

  return (
    // @ts-expect-error - due to limitation of storyblok types returning text where literals is expected
    <Text
      display="inline"
      {...textStyles}
      className="rich-text-inline"
      {...storyblokEditable(blok)}
    >
      ${page.extra.salaryInsightInfo.countryOnly_medianSalary}
    </Text>
  );
}
