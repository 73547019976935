import { initializeApollo } from '@apollo-client';
import {
  type SelectSalaryDataEligibilityQueryVariables,
  type SelectSalaryDataEligibilityQuery,
  SelectSalaryDataEligibility,
} from './data';
import { type SalaryInsightEligbility } from './types';
import { serializeSalaryInsightEligbility } from './serializer';

export async function fetchSalaryInsightEligibility(
  version: number,
): Promise<SalaryInsightEligbility> {
  const apolloClient = initializeApollo();

  const { data, error } = await apolloClient.query<
    SelectSalaryDataEligibilityQuery,
    SelectSalaryDataEligibilityQueryVariables
  >({
    query: SelectSalaryDataEligibility,
    context: { clientName: 'hasura' },
    fetchPolicy: 'cache-first',
    variables: {
      version,
    },
  });

  if (error) {
    throw new Error(`Error fetching Salary Data Eligibility: ${error}`);
  }

  return serializeSalaryInsightEligbility(data);
}
