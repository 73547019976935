import { useRouter } from 'next/router';

export function useAugmentedRouter(): {
  router: ReturnType<typeof useRouter>;

  /**
   * Returns a string of query parameters that are not 'slug'
   */
  queryString: string;

  /**
   * Returns a string of query parameters that are not 'slug' with a leading '?'
   */
  queryStringText: string;
} {
  const router = useRouter();
  const queryString = Object.entries(router.query)
    .filter(([key]) => !['slug'].includes(key))
    .map(([key, value]) => `${key}=${value}`)
    .join('&');
  const queryStringText = `${queryString ? `?${queryString}` : ''}`;

  return { router, queryString, queryStringText };
}
