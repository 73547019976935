import { storyblokEditable, StoryblokComponent } from '@storyblok/react';
import { Box, Container, SimpleGrid } from '@terminal/design-system';
import type { GridStoryblok, Storyblok } from '../types';
import { selectBlokStyles } from '@helpers/storyblok.utils';

export function Grid({ blok, page }: Storyblok<GridStoryblok>) {
  const {
    gridStyles,
    marginStyles,
    textStyles,
    layoutStyles,
    generalStyles,
    paddingStyles,
    sizesStyle,
  } = selectBlokStyles(blok);

  const _Grid = (
    <SimpleGrid
      {...storyblokEditable(blok)}
      // @ts-ignore columns expect an array of numbers or number, however storyblok returns a string
      columns={gridStyles.columns || [1, 2, 3, 4]}
      alignItems={gridStyles.alignItems || 'stretch'}
      justifyItems="center"
      spacing={gridStyles.spacing || 8}
      spacingX={gridStyles.spacingX}
      spacingY={gridStyles.spacingY}
      {...textStyles}
      // @ts-ignore text align expect a certain literal, however storyblok retunrs string
      textAlign={textStyles.textAlign || 'left'}
      {...sizesStyle}
      {...(!blok.full_width
        ? {
            ...generalStyles,
            ...marginStyles,
            ...paddingStyles,
            ...layoutStyles,
          }
        : {})}
    >
      {blok.columns?.map((nestedBlok) => (
        <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} page={page} />
      ))}
    </SimpleGrid>
  );

  if (blok.full_width) {
    return _Grid;
  }

  return (
    <Box
      {...storyblokEditable(blok)}
      {...marginStyles}
      {...generalStyles}
      {...layoutStyles}
      {...paddingStyles}
    >
      <Container>{_Grid}</Container>
    </Box>
  );
}
