import { FormControl, FormErrorMessage } from '@chakra-ui/react';
import slugify from '@helpers/slugify';
import { Flex, FormLabel, Select, Button } from '@terminal/design-system';
import {
  roleAbbreviationToNameMapping,
  roleAbbreviationToSlugMapping,
  SalaryInsightSlugs,
} from '@_pages/salary-insight/constants';
import type { SalaryInsightFindReportNavigationStoryblok, Storyblok } from '../types';
import { storyblokEditable } from '@storyblok/react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Link from 'next/link';
import { useAugmentedRouter } from 'hooks/useAugmentedRouter';

const validationSchema = Yup.object({
  country: Yup.string().required('Please select a hiring market'),
});

export function SalaryInsightFindReportNavigation({
  blok,
  page,
}: Storyblok<SalaryInsightFindReportNavigationStoryblok>) {
  const { queryStringText } = useAugmentedRouter();

  const formik = useFormik({
    initialValues: {
      country: '',
      role: '',
    },
    validationSchema,
    onSubmit: (values) => {},
  });

  const url =
    blok.urlTemplate
      // If no country is selected, default to Canada so that SEO crawlers can still consider
      // this a valid link in our site. Even though the user wont be redirected to this link
      // as long as they don't select a country,.
      .replace('{country}', slugify(formik.values.country || 'canada'))
      .replace(
        '{role}',
        formik.values.role === SalaryInsightSlugs.allRoles || !formik.values.role
          ? ''
          : roleAbbreviationToSlugMapping[formik.values.role],
      ) + queryStringText;

  return (
    <form onSubmit={formik.handleSubmit} {...storyblokEditable(blok)}>
      <Flex gap="4" flexDirection={{ base: 'column', md: 'row' }} mb="4" alignItems="flex-end">
        <FormControl isInvalid={formik.touched.country && Boolean(formik.errors.country)}>
          <FormLabel>Hiring Market</FormLabel>
          <Select
            placeholder="Select a country"
            id="country"
            name="country"
            value={formik.values.country}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          >
            {Object.keys(page.extra.salaryInsightEligbility).map((country) => (
              <option value={country} key={`country-${country}`}>
                {country}
              </option>
            ))}
          </Select>
          {formik.touched.country && formik.errors.country && (
            <FormErrorMessage>{formik.errors.country}</FormErrorMessage>
          )}
        </FormControl>

        <FormControl isInvalid={formik.touched.role && Boolean(formik.errors.role)}>
          <FormLabel>Role</FormLabel>
          <Select
            id="role"
            name="role"
            value={formik.values.role}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            disabled={!formik.values.country}
          >
            <option value={SalaryInsightSlugs.allRoles}>All Roles</option>
            {formik.values.country
              ? Object.keys(
                  page.extra.salaryInsightEligbility[
                    formik.values.country as keyof typeof page.extra.salaryInsightEligbility
                  ],
                ).map((role) => (
                  <option value={role} key={`role-${role}`}>
                    {roleAbbreviationToNameMapping[role]}
                  </option>
                ))
              : []}
          </Select>
          {formik.touched.role && formik.errors.role && (
            <FormErrorMessage>{formik.errors.role}</FormErrorMessage>
          )}
        </FormControl>

        <Button
          type="submit"
          w={['full', 'full', 'initial']}
          as={Link}
          href={url}
          onClick={(e) => {
            if (!formik.values.country) {
              e.preventDefault();
              formik.handleSubmit();
            }
          }}
        >
          See Salaries
        </Button>
      </Flex>
    </form>
  );
}
