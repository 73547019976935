import { forwardRef, useRef, useState, useEffect } from 'react';
import { storyblokEditable } from '@storyblok/react';
import { useInView } from 'react-intersection-observer';
import { useTab, useMultiStyleConfig } from '@chakra-ui/react';
import {
  Heading,
  Text,
  Box,
  GridItem,
  Flex,
  Grid,
  Tabs,
  TabList,
  Button,
  Icon,
} from '@terminal/design-system';
import { selectBlokStyles } from '@helpers/storyblok.utils';
import { Storyblok, ExpandableStepListStoryblok } from '../types';
import { RichText } from '../primitive/RichText';

export function ExpandableStepList({ blok, page }: Storyblok<ExpandableStepListStoryblok>) {
  const { marginStyles, paddingStyles } = selectBlokStyles(blok);

  const [ref, inView] = useInView({
    rootMargin: '-40% 0%',
    triggerOnce: true,
  });

  const [tabIndex, setTabIndex] = useState(0);

  const handleTabsChange = (index) => {
    setTabIndex(index);
  };

  useEffect(() => {
    if (blok.disableAutoRotation) return;
    if (inView) {
      const timeout = setTimeout(() => {
        let next = tabIndex + 1;
        if (next === blok.items.length) {
          next = 0;
        }
        setTabIndex(next);
      }, 4000);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [inView, tabIndex, blok.items.length, blok.disableAutoRotation]);

  const CustomTab = forwardRef((props: any, ref) => {
    const tabProps = useTab({ ...props, ref });
    const isSelected = !!tabProps['aria-selected'];
    const styles = useMultiStyleConfig('Tabs', tabProps);
    const selectedContainer = useRef(null);

    return (
      <Button
        __css={styles.tab}
        {...tabProps}
        py="8"
        px="0"
        textAlign="left"
        position="relative"
        border="none"
        _before={{
          content: '""',
          position: 'absolute',
          left: '4rem',
          right: '0',
          margin: '0 auto',
          bottom: '0',
          width: 'calc(100% - 4rem)',
          height: 'px',
          bgColor: 'blue.200',
        }}
        _last={{
          _before: {
            display: 'none',
          },
        }}
        _active={{
          bgColor: 'blue.100',
        }}
        _focus={{
          boxShadow: isSelected ? 'none' : 'var(--chakra-shadows-outline)',
        }}
      >
        <Flex alignItems="center">
          <Box w="8" h="8" mr="8">
            {blok.type === 'number' && (
              <Flex
                w="100%"
                h="100%"
                color="white"
                fontSize="xl"
                fontWeight="bold"
                lineHeight="5"
                bgColor={isSelected ? 'green.500' : 'grey.300'}
                alignItems="center"
                justifyContent="center"
              >
                {props.index}
              </Flex>
            )}
            {blok.type === 'pin' && (
              <Icon
                viewBox="0 0 40 40"
                w="100%"
                h="100%"
                color={isSelected ? 'green.500' : 'grey.900'}
                position="relative"
                zIndex="2"
                transition="color 0.3s ease-in-out"
              >
                <path
                  d="M33.3337 16.0318C33.3337 23.0449 20.0003 36.6667 20.0003 36.6667C20.0003 36.6667 6.66699 23.0449 6.66699 16.0318C6.66699 9.01865 12.6365 3.33337 20.0003 3.33337C27.3641 3.33337 33.3337 9.01865 33.3337 16.0318Z"
                  fill="currentColor"
                  stroke="currentColor"
                  strokeWidth="3.33333"
                />
                <circle cx="20" cy="16.6666" r="5" fill="white" />
              </Icon>
            )}
          </Box>
          <Heading variant="heading-3" lineHeight="9" fontWeight={isSelected ? 'bold' : 'normal'}>
            {props.title}
          </Heading>
        </Flex>
        <Box
          pl="16"
          mt="2"
          lineHeight="6"
          maxHeight={isSelected ? selectedContainer.current.scrollHeight : '0'}
          transition="max-height .3s ease-in-out"
          overflow="hidden"
          ref={selectedContainer}
        >
          <Text>{props.text}</Text>
        </Box>
      </Button>
    );
  });

  return (
    <Box
      paddingTop={paddingStyles.paddingTop || 0}
      paddingBottom={paddingStyles.paddingBottom || 0}
      paddingLeft={paddingStyles.paddingLeft || 0}
      paddingRight={paddingStyles.paddingRight || 0}
      {...marginStyles}
      {...storyblokEditable(blok)}
    >
      <Tabs
        isLazy
        id="tabs"
        variant="unstyled"
        orientation="vertical"
        defaultIndex={Number(blok.defaultIndex || 0)}
        index={tabIndex}
        onChange={handleTabsChange}
        ref={ref}
      >
        <Grid
          gridTemplateColumns={{ base: '1fr', lg: blok.isReversed ? '5.5fr 4.5fr' : '4.5fr 5.5fr' }}
          columnGap={{ lg: '14' }}
        >
          <GridItem
            gridArea={{ base: '1/1/1/3', lg: blok.isReversed ? '1 / 2 / 3 / 3' : '1 / 1 / 3 / 2' }}
            alignSelf="center"
            mb={{ base: 4, lg: 0 }}
          >
            <Box>
              <RichText
                blok={{
                  _uid: blok.heroSection?._uid,
                  component: 'richText',
                  content: blok.heroSection,
                }}
                page={page}
              />
            </Box>
          </GridItem>
          <GridItem gridArea={blok.isReversed ? '2 / 1 / 3 / 2' : '2 / 2 / 3 / 3'}>
            <TabList>
              {blok.items.map((tab, index) => (
                <CustomTab key={index} index={index + 1} title={tab.title} text={tab.text} />
              ))}
            </TabList>
          </GridItem>
        </Grid>
      </Tabs>
    </Box>
  );
}
