import { Role_Choices_Enum } from '@terminal/global/types/hasura-tables.generated.types';
import { UniversalSeniorityLevel } from './types';

export const roleAbbreviationToNameMapping: Record<Role_Choices_Enum, string> = {
  FED: 'Frontend Developer',
  BED: 'Backend Developer',
  MQA: 'Manual QA',
  DEV: 'DevOps Developer',
  FSD: 'Full Stack Developer',
  MOD: 'Mobile Developer',
  DSC: 'Data Scientist',
  ENL: 'Eng. Lead',
  DES: 'Designer',
  AQA: 'Automation QA',
  DEN: 'Data Engineer',
  DAN: 'Data Analyst',
  AML: 'AI/ML Developer',
  SCM: 'Scrum Master',
  PRM: 'Product Manager',
} as const satisfies Record<Role_Choices_Enum, string>;

export const roleNameToSlugMapping = {
  'Frontend Developer': 'frontend-developer',
  'Backend Developer': 'backend-developer',
  'Manual QA': 'manual-qa',
  'DevOps Developer': 'devops-developer',
  'Full Stack Developer': 'full-stack-developer',
  'Mobile Developer': 'mobile-developer',
  'Data Scientist': 'data-scientist',
  'Eng. Lead': 'eng-lead',
  Designer: 'designer',
  'Automation QA': 'automation-qa',
  'Data Engineer': 'data-engineer',
  'Data Analyst': 'data-analyst',
  'AI/ML Developer': 'ai-ml-developer',
  'Scrum Master': 'scrum-master',
  'Product Manager': 'product-manager',
} as const satisfies Record<
  (typeof roleAbbreviationToNameMapping)[keyof typeof roleAbbreviationToNameMapping],
  string
>;

export const roleAbbreviationToSlugMapping: Record<
  keyof typeof roleAbbreviationToNameMapping,
  (typeof roleNameToSlugMapping)[keyof typeof roleNameToSlugMapping]
> = {
  FED: 'frontend-developer',
  BED: 'backend-developer',
  MQA: 'manual-qa',
  DEV: 'devops-developer',
  FSD: 'full-stack-developer',
  MOD: 'mobile-developer',
  DSC: 'data-scientist',
  ENL: 'eng-lead',
  DES: 'designer',
  AQA: 'automation-qa',
  DEN: 'data-engineer',
  DAN: 'data-analyst',
  AML: 'ai-ml-developer',
  SCM: 'scrum-master',
  PRM: 'product-manager',
};

export const SalaryInsightSlugs = {
  allRoles: 'all-roles',
  allLevels: 'all-levels',
  root: 'remote-software-engineer-salaries',
} as const;

export const salaryDataVersion = 1;

export const levelAbbrToSlugMapping = {
  L1: 'entry',
  L2: 'junior',
  L3: 'mid-level',
  L4: 'senior',
  L5: 'staff',
  L6: 'principal',
} as const satisfies Record<UniversalSeniorityLevel, string>;

export const levelAbbrToFilterTitleMapping = {
  L1: 'Entry',
  L2: 'Junior',
  L3: 'Mid',
  L4: 'Senior / Lead',
  L5: 'Staff',
  L6: 'Principal',
} as const satisfies Record<UniversalSeniorityLevel, string>;

export const levelAbbrToCopyTitleMapping = {
  L1: 'Entry',
  L2: 'Junior',
  L3: 'Mid-level',
  L4: 'Senior',
  L5: 'Staff',
  L6: 'Principal',
} as const satisfies Record<UniversalSeniorityLevel, string>;
