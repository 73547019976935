import { Box, Flex, GridItem, Link } from '@terminal/design-system';
import { Storyblok, FooterSectionStoryblok } from '../types';
import { storyblokEditable } from '@storyblok/react';

export function FooterSection({ blok }: Storyblok<FooterSectionStoryblok>) {
  const { title, subSections } = blok;
  return (
    <GridItem
      key={title}
      pb={{ md: '14' }}
      position="relative"
      _before={{
        md: {
          content: '""',
          position: 'absolute',
          right: '0',
          top: '0',
          width: 'px',
          height: '100%',
          bgColor: 'grey.900',
          opacity: '0.2',
        },
      }}
      _last={{
        _before: {
          display: 'none',
        },
      }}
      {...storyblokEditable(blok)}
    >
      <Box
        mb={{ base: '6', lg: '8' }}
        color="blue.500"
        fontSize={{ base: 'md', md: 'md' }}
        lineHeight={{ base: '4' }}
        fontWeight="700"
        textTransform="uppercase"
      >
        {title}
      </Box>
      {subSections?.length && (
        <Flex flexDir="column" alignItems="start" gap={4} mr={4}>
          {subSections.map((subSection) => (
            <div key={subSection.title || subSection.link.story?.name}>
              <Link
                key={subSection.title || subSection.link.story?.name}
                href={subSection.link.url || subSection.link.story?.url || ''}
                py="2"
                fontSize={{ base: 'md', md: 'md' }}
                lineHeight="4"
                color="grey.900"
                _hover={{
                  textDecoration: 'none',
                  '&::before': {
                    bgColor: 'green.500',
                  },
                }}
                position="relative"
                // TODO: fix this idk how it works well, but probably is better to use subSection.link.target
                target={subSection.href === '/work-for-terminal' ? '_blank' : '_self'}
                _before={{
                  content: '""',
                  position: 'absolute',
                  bottom: 0,
                  w: 'full',
                  h: '0.5',
                  transition: 'background-color 0.2s ease-in-out',
                  bgColor: 'transparent',
                }}
              >
                {subSection.title || subSection.link.story?.name}
              </Link>
              {subSection.subLinks.tbody.map((subLink) => (
                <Link
                  key={subLink._uid}
                  fontSize={{ base: 'md' }}
                  href={subLink.body[1].value}
                  px={4}
                >
                  {subLink.body[0].value}
                </Link>
              ))}
            </div>
          ))}
        </Flex>
      )}
    </GridItem>
  );
}
