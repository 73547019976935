import { Button } from '@chakra-ui/react';
import { Box, Flex, ChevronRightIcon } from '@terminal/design-system';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useState } from 'react';
import { Navigation } from 'swiper';
import 'swiper/css';
import { StoryblokComponent, storyblokEditable } from '@storyblok/react';
import type { SliderStoryblok, Storyblok } from '../types';
import { selectBlokStyles } from '@helpers/storyblok.utils';

const buttonThemeProps = {
  light: {
    bg: 'bg.primary',
    color: 'text.inverse',
    _hover: { bg: 'bg.tertiary' },
    _active: { bg: 'bg.tertiary' },
    _disabled: { color: 'text.disabled', bg: 'text.disabled', cursor: 'not-allowed' },
    sx: { '> svg': { fill: 'ui.primary' } },
  },
  dark: {
    bg: 'brand.main',
    color: 'bg.primary',
    _hover: { bg: 'brand.darkest' },
    _active: { bg: 'brand.darkest' },
    _disabled: { color: 'text.disabled', bg: 'text.disabled', cursor: 'not-allowed' },
    sx: { '> svg': { fill: 'bg.primary' } },
  },
};

export function Slider({ blok, page }: Storyblok<SliderStoryblok>) {
  const [prevEl, setPrevEl] = useState<HTMLElement>(null);
  const [nextEl, setNextEl] = useState<HTMLElement>(null);

  const { marginStyles } = selectBlokStyles(blok);

  return (
    <Box as="section" {...marginStyles} {...storyblokEditable(blok)}>
      <Box
        as={Swiper}
        py="4"
        pl={{
          base: 'calc(50% - 180px)',
          md: 'calc(50% - 350px)',
          lg: 'calc(50% - 460px)',
          xl: 'calc(50% - 550px)',
        }}
        pr="6"
        pb="16"
        slidesPerView={'auto'}
        navigation={{ prevEl, nextEl }}
        modules={[Navigation]}
        speed={1000}
        autoHeight={true}
        spaceBetween={32}
        simulateTouch={false}
      >
        {blok.slides.map((nestedBlok) => (
          <SwiperSlide
            key={nestedBlok._uid}
            style={{ width: blok.slideWidth ? `${blok.slideWidth}px` : '20.5rem' }}
          >
            <StoryblokComponent blok={nestedBlok} page={page} />
          </SwiperSlide>
        ))}
      </Box>
      <Flex mx="auto" maxW="4.5rem" justifyContent="space-between">
        <Button
          {...buttonThemeProps[blok?.buttonTheme ?? 'light']}
          borderRadius="full"
          minW="6"
          h="6"
          p="0"
          ref={(node) => setPrevEl(node)}
        >
          <ChevronRightIcon
            transform="rotateY(180deg)"
            w={4}
            h={3}
            aria-label="Arrow icon pointing to the left"
          />
        </Button>
        <Button
          {...buttonThemeProps[blok?.buttonTheme ?? 'light']}
          borderRadius="full"
          minW="6"
          h="6"
          p="0"
          ref={(node) => setNextEl(node)}
        >
          <ChevronRightIcon w={4} h={3} aria-label="Arrow icon pointing to the right" />
        </Button>
      </Flex>
    </Box>
  );
}
