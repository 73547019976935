import { storyblokEditable } from '@storyblok/react';
import { Box, Flex, Heading, SimpleGrid, Text } from '@terminal/design-system';
import { OutlineButton } from '@components/UI/Button';
import {
  roleAbbreviationToNameMapping,
  roleAbbreviationToSlugMapping,
  SalaryInsightSlugs,
} from '@_pages/salary-insight/constants';
import { Storyblok, SalaryInsightListStoryblok } from '../types';
import { useAugmentedRouter } from 'hooks/useAugmentedRouter';

export function SalaryInsightList({ blok, page }: Storyblok<SalaryInsightListStoryblok>) {
  const { queryStringText } = useAugmentedRouter();

  return (
    <Box py={20} {...storyblokEditable(blok)}>
      <Box>
        <Heading as="h3" variant="heading-3">
          Browse Global Remote Software Engineer Salaries
        </Heading>
        <Text>
          Explore salaries for remote software engineers working for U.S. companies from Canada,
          Latin America, and Europe.
        </Text>
      </Box>
      <Flex flexWrap="wrap" gap={12} mt={12}>
        {Object.entries(page.extra.salaryInsightEligbility).map(([country, rolesMap]) => (
          <Flex key={country} flexDirection="column" w="full">
            <Text variant="label">{country}</Text>
            <SimpleGrid
              justifyContent="flex-start"
              columns={{ base: 2, md: 3, lg: 4, xl: 5 }}
              gap={6}
              w="full"
              mt={6}
            >
              {Object.keys(rolesMap).map((role) => (
                <OutlineButton
                  as="a"
                  key={`salary-insight-tag-${role}`}
                  href={`/${SalaryInsightSlugs.root}/${country.toLowerCase()}/${
                    roleAbbreviationToSlugMapping[role]
                  }${queryStringText}`}
                  fontSize="sm"
                  fontWeight={blok.whitePill ? '700' : 'normal'}
                  borderRadius="6.25rem"
                  color="grey.900"
                  border="none"
                  px={3}
                  py={1}
                  h="2.25rem"
                  textAlign="center"
                  bgColor={blok.whitePill ? 'white' : 'bg.secondary'}
                  _hover={{
                    backgroundColor: 'blue.100',
                    boxShadow: 'md',
                  }}
                >
                  {roleAbbreviationToNameMapping[role]}
                </OutlineButton>
              ))}
            </SimpleGrid>
          </Flex>
        ))}
      </Flex>
    </Box>
  );
}
