import { storyblokEditable, StoryblokComponent } from '@storyblok/react';
import { Box, Container, Flex } from '@terminal/design-system';
import type { StackStoryblok, Storyblok } from '../types';
import { selectBlokStyles } from '@helpers/storyblok.utils';
import * as CSS from 'csstype';

export function Stack({ blok, page }: Storyblok<StackStoryblok>) {
  const {
    flexStyles,
    marginStyles,
    textStyles,
    layoutStyles,
    generalStyles,
    paddingStyles,
    sizesStyle,
  } = selectBlokStyles(blok);

  const _Flex = (
    <Flex
      direction={(flexStyles?.direction as CSS.Property.FlexDirection) ?? ['column', 'row']}
      flexWrap={(flexStyles?.flexWrap as CSS.Property.FlexWrap) || 'wrap'}
      gap={flexStyles?.gap || 8}
      alignItems={flexStyles?.alignItems || 'stretch'}
      alignContent={flexStyles?.alignContent}
      justifyContent={flexStyles?.justifyContent || 'flex-start'}
      justifyItems={flexStyles?.justifyItems || 'center'}
      flex={flexStyles?.flex}
      {...storyblokEditable(blok)}
      textAlign={(textStyles.textAlign as CSS.Property.TextAlign) || 'left'}
      {...sizesStyle}
      {...(blok.fullWidth
        ? {
            ...marginStyles,
            ...paddingStyles,
            ...layoutStyles,
            ...generalStyles,
          }
        : {})}
    >
      {blok.columns?.map((nestedBlok) => (
        <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} page={page} />
      ))}
    </Flex>
  );

  if (blok.fullWidth) {
    return _Flex;
  }

  return (
    <Box
      {...storyblokEditable(blok)}
      {...marginStyles}
      {...generalStyles}
      {...layoutStyles}
      {...paddingStyles}
    >
      <Container>{_Flex}</Container>
    </Box>
  );
}
