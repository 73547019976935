import gql from 'graphql-tag';

export const SelectSalaryDataEligibility = gql`
  query SelectSalaryDataEligibility($version: Int!) @cached(ttl: 604800) {
    salary_data_eligibility(where: { version_id: { _eq: $version } }) {
      country
      role
      level
    }
  }
`;
